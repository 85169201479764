<template>
  <el-dialog
    title="设置通知地址"
    :close-on-click-modal="false"
    :visible.sync="visible"
    width="540px"
  >
    <el-form :model="dataForm" ref="dataForm" @keyup.enter.native="dataFormSubmit()" label-width="80px" :rules="rules">
      <el-form-item label="单位名称" prop="id">
        <el-input
          class="fixedWidth400"
          v-model="dataForm.nodeName"
          placeholder="单位名称"
          :disabled="true"
        ></el-input>
      </el-form-item>
      <el-form-item label="通知地址" prop="noticeUrl" ref="noticeUrl">
        <el-input
          class="fixedWidth400"
          v-model="dataForm.noticeUrl"
          placeholder="通知地址"
        ></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false">取消</el-button>
      <el-button type="primary" @click="dataFormSubmit()">确定</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  props: {
    info: {
      type: Object,
      default: () => {
        return {
          id: "",
          nodeName: "",
          noticeUrl:""
        };
      },
    },
  },
  data() {
    return {
      visible: false,
      roleList: [],
      dataForm: {id:"",nodeName:"",noticeUrl:""},
      isRules: true,
      rules:{
       noticeUrl:[
          {required:true,message:"请输入通知地址",trigger:'blur'}
      ]
  },
    };
  },
  methods: {
    init() {
      this.visible = true;
      this.$nextTick(() => {
        this.$refs["dataForm"].resetFields();
        this.dataForm = JSON.parse(JSON.stringify(this.info));
      })
    },
    // 表单提交
    dataFormSubmit() {
      this.$refs["dataForm"].validate((valid) => {
        if (valid) {
          const Loading = this.$mask();
          this.$http({
            url: this.$http.adornUrl("/obd/notice/save"),
            method: "post",
            params: {
              orgId: this.dataForm.id,
              noticeUrl: this.dataForm.noticeUrl.trim(),
            },
          })
            .then((res) => {
              Loading.close();
              this.$message.success(res.data.msg);
              this.$refs["dataForm"].resetFields();
              this.visible = false;
              this.$emit("refreshList");
            })
            .catch((err) => {
              Loading.close();
              this.$message.error(err.msg);
            });
        }
      });
    },
  },
};
</script>
